<template>
  <div class="product">
    <div class="xzdh">
      <div class="xzdh_box">
        <p>智能办会</p>
        <p>一个流程轻松解决会前准备工作</p>
        <p>提高会前准备工作效率，大幅减少工作量</p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/znbh/hxld.png" />
        <div class="hxld_box_content" id="title">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/hxld_icon1.png"
            />
            <p class="first_line">会议签批</p>
            <p class="second_line">支持表格式、发电类会议通知签批</p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/hxld_icon2.png"
            />
            <p class="first_line">会议室申请</p>
            <p class="second_line">
              线上申请会议室，系统智能解决会议室使用冲突
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="hytz">
      <div class="hytz_box">
        <div class="hytz_box_content" id="title2">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/hytz_icon1.png"
            />
            <p class="first_line">会议室日程安排</p>
            <p class="second_line">
              会议室申请通过后自动形成<br />会议室日程，方便会议室管理员跟会
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/hytz_icon2.png"
            />
            <p class="first_line">会议通知</p>
            <p class="second_line">
              会议召开申请通过后，统一线上<br />和短信通知参会单位，无需电话通知
            </p>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/znbh/hytz.png"
        />
      </div>
    </div>
    <div class="chbm">
      <div class="chbm_box">
        <img
          class="img"
          id="img3"
          src="@/assets/images/product/znbh/chbm.png"
        />
        <div class="chbm_box_content" id="title3">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/chbm_icon1.png"
            />
            <p class="first_line">参会报名</p>
            <p class="second_line">
              参会单位收到会议通知后，<br />进行参会报名，组织单位一键导出参会名单
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/znbh/chbm_icon2.png"
            />
            <p class="first_line">领导日程安排</p>
            <p class="second_line">参会报名或请假后，自动形成领导日程</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hytzH = $('.hytz').offset().top
      var chbmH = $('.chbm').offset().top
      if (h > hytzH - 500) {
        $('#img2').addClass('animation')
      }
      if (h > chbmH - 500) {
        $('#img3').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
